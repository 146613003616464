.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust this for transparency */
  z-index: 9999;
  opacity: 1; /* No need for high opacity */
}

.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid rgba(243, 243, 243, 0.7); /* Make the spinner border slightly transparent */
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
